import KatalLogger, { Level } from '@amzn/katal-logger';

const katalLoggerConfig = {
  url: 'prod.chub-log.prod.controllershiphub.finergy.amazon.dev',
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: false,
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();
